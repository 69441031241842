
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton =  () => {
  const queryParams = new URLSearchParams(window.location.search)
       
  const code = parseInt(queryParams.get('code')?.toString()??"");
  const { logout } =  useAuth0();
  logout({ returnTo: window.location.origin });
console.log(window.location.origin)
  return (
    <div>
      <h1>...Incorrect Login Details!!</h1>
    </div>
  );
};

export default LogoutButton;