import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './sass/core.scss'

import { Auth0Provider } from "@auth0/auth0-react";
var REACT_APP_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN as string
const REACT_APP_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID as string
//const redirectUri = process.env.REACT_APP_AUTH_CALLBACK_URL;
const audience = process.env.REACT_APP_AUTH_AUDIENCE;
const scope = process.env.REACT_APP_AUTH_SCOPE;

ReactDOM.render(
  
  <React.StrictMode>
    <Auth0Provider
    
      domain={REACT_APP_AUTH_DOMAIN}
      clientId={REACT_APP_AUTH_CLIENT_ID}
      redirectUri={window.location.origin}   
      audience={audience}
      scope={scope}
      useRefreshTokens={true}
      prompt={"login"}
      cacheLocation="localstorage"    
      
     >
      
       <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
