import {FC, useEffect, useRef, useState} from 'react'
import DatePicker from "react-datepicker";
import { HubConnectionBuilder, HttpTransportType, HubConnection } from '@microsoft/signalr';
import axios from 'axios'
interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface Stats 
{
    accountingStats: AccountingStats,
    teacherSalaries: Array<Salary>
    coverLessons: Array<CoverLesson>
    lessonsWithAdjustments:Array<AdjustmentLesson>,
    mslcTeacherSalaries:Array<MslcSalary>,
    totalFee: number
}

interface AccountingStats 
{
    revenue:string,
    salaries:string,
    numberOfLessons:string,
    oaFee:string,
    oaPlanOneFee:number,
    adminFee: number
}

interface Salary 
{
    id:string,
    firstName:string,
    lastName:string,
    payFromPaidLessons:string,
    payFromUnPaidLessons:string,
    payFromSelfInvoicedLessons:string,
    amountRefundedFromLessons:string,
    accountId:string
}

interface MslcSalary 
{
    id:string,
    firstName:string,
    lastName:string,
    pay:number
}

interface CoverLesson 
{
    id: string,
    teacher: string,
    student: string,
    hours: number,
    subject: string,
    lessonDate: string
}

interface AdjustmentLesson 
{
    teacher: string,
    student: string,
    fee: number,
    pay: number,
    padj:number,
    fadj:number
}
const REACT_APP_SERVER = process.env.REACT_APP_SERVER

const Adhaan:FC<Props> = ({data}) => 
{
    const [isStreamStarted, setIsStreamStarted] = useState(false)
    const [play, setPlay] = useState(false);
    const [time, setTime] = useState("");
    const [timings, setTimeings] = useState<any | null>({})
    const [nextParayer, setNextPrayer] = useState<any>(null)
    const [connection, setConnection] = useState<HubConnection | null>(null)

    const nextPrayerRef = useRef(nextParayer);
    const isStreamStartedRef = useRef(isStreamStarted)
    nextPrayerRef.current = nextParayer;
    isStreamStartedRef.current = isStreamStarted


    useEffect(()=>{
        const connection = new HubConnectionBuilder()
        .withUrl(`${REACT_APP_SERVER}chathub`,{skipNegotiation: true,
            transport: HttpTransportType.WebSockets}) 
            .withAutomaticReconnect()
            .build();

        setConnection(connection)
    
        
    },[])

    useEffect(()=>{
        if(connection){
            connection.start().then(()=>{
                console.log("start")
                connection.on('RecieveMessage', (message:any) => {
                    console.log('Received message:', message, play);
                   setPlay(true)
                   setTimeout(()=>{ setPlay(false)}, 230000)
                    
                });
            });
         
        
           
        }
    
        
    },[connection])
    
    const reload = () => {
 
        axios.get('/api/internal/help/signal')
        .then(response=>{
        
        })
    }



    useEffect(()=>{      

        startTime()
      },[])
      function startTime() {
        const today = new Date();
        let h = today.getHours();
        let m = today.getMinutes();
        let s = today.getSeconds();
        
        setTime(h + ":" + m + ":" + s);
        setTimeout(startTime, 1000);
      }
      
     
    return(
        <div className='container'>
            <h1>OA Stream</h1> 
            <h3>{time}</h3>
      <button className="btn btn-outline-primary mr-3" onClick={()=> reload()}>reload in next min</button>
      <button className='btn btn-primary' onClick={()=> setIsStreamStarted(true)}>start stream</button>
      <p>stream status: {isStreamStarted? "started": "not started"}</p>
       <p>Next prayer: {nextParayer?.name}</p> 

      {
        play && isStreamStarted?
        <audio muted={!isStreamStarted} playsInline={true} controls autoPlay={true}>
          <source src={"/adhaan.mp3"} type="audio/mp3"/>
          Your browser does not support the audio element.
        </audio>
        
        :null
      }
        


           


        </div>
    )
}


export default Adhaan